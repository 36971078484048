import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { Lang } from '@app/core/types/lang.types';
import { UtilsService } from '@app/shared/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
class CanonicalGuardService {
  constructor(private utilsSrv: UtilsService) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url;

    let canonicalUrl: string;

    if (url === '/') {
      canonicalUrl = environment.appUrl;
    } else {
      canonicalUrl = `${environment.appUrl}${url}`;
    }

    this.updateCanonicalUrl(canonicalUrl);
    this.updateHreflang(url, canonicalUrl);

    return true;
  }

  private updateCanonicalUrl(url: string) {
    let link: HTMLLinkElement = this.utilsSrv.document.querySelector("link[rel='canonical']");

    if (link) {
      this.utilsSrv.renderer.setAttribute(link, 'href', url);
    } else {
      link = this.utilsSrv.renderer.createElement('link');
      this.utilsSrv.renderer.setAttribute(link, 'rel', 'canonical');
      this.utilsSrv.renderer.setAttribute(link, 'href', url);
      this.utilsSrv.renderer.appendChild(this.utilsSrv.document.head, link);
    }
  }

  private updateHreflang(url: string, canonicalUrl: string) {
    const languageMap: { [key: string]: Lang } = {
      '/es/': 'es',
      '/en/': 'en',
    };

    let selectedLanguage: Lang;

    for (const path in languageMap) {
      if (url.includes(path)) {
        selectedLanguage = languageMap[path];
        break;
      }
    }

    if (selectedLanguage) {
      let hreflangLink: HTMLLinkElement = this.utilsSrv.document.querySelector(
        `link[rel='alternate'][hreflang='${selectedLanguage}']`,
      );

      if (hreflangLink) {
        this.utilsSrv.renderer.setAttribute(hreflangLink, 'href', canonicalUrl);
      } else {
        hreflangLink = this.utilsSrv.renderer.createElement('link');
        this.utilsSrv.renderer.setAttribute(hreflangLink, 'rel', 'alternate');
        this.utilsSrv.renderer.setAttribute(hreflangLink, 'hreflang', selectedLanguage);
        this.utilsSrv.renderer.setAttribute(hreflangLink, 'href', canonicalUrl);
        this.utilsSrv.renderer.appendChild(this.utilsSrv.document.head, hreflangLink);
      }
    }
  }
}

export const canonicalGuard: CanActivateChildFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
  inject(CanonicalGuardService).canActivateChild(next, state);
