import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { StatePageConfig } from '@app/application/interfaces/state.interface';
import { StateService } from '@app/application/services/state/state.service';

@Injectable({
  providedIn: 'root',
})
class PageConfigGuardService {
  constructor(private readonly stateSrv: StateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const pageConfigRouteData: StatePageConfig = route.data['pageConfig'] || {};
    const { header, footer, headerActions, headerBorderBottom } = pageConfigRouteData;

    this.stateSrv.update<StatePageConfig>('pageConfig', {
      header: header ?? true,
      footer: footer ?? true,
      headerActions: headerActions ?? true,
      headerBorderBottom: headerBorderBottom ?? true,
    });

    return true;
  }
}

export const pageConfigGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
  inject(PageConfigGuardService).canActivate(next, state);
