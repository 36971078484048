import { CommonModule } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { BaseComponent } from '../base/base.component';
import { BrandComponent } from '../brand/brand.component';
import { IconComponent } from '../icon/icon.component';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';
import { MobileSidenavComponent } from '../mobile-sidenav/mobile-sidenav.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    BrandComponent,
    LanguageSwitcherComponent,
    AvatarComponent,
    MobileSidenavComponent,
    IconComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends BaseComponent {
  @ViewChild('mobileSidenavTpl') mobileSidenavTpl: TemplateRef<any>;

  openMobileMenu() {
    this.sidenavSrv.open({ tpl: this.mobileSidenavTpl, config: { position: 'end' }, styles: ['mobile-sidenav'] });
  }
}
