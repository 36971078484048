import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { SEOParams } from '@app/core/interfaces/seo.interface';
import { LanguageService } from '@app/core/services/language/language.service';
import { MetaTagsService } from '@app/core/services/meta-tags/meta-tags.service';
import { UtilsService } from '@app/shared/services/utils/utils.service';
import { first, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class MetaTagsGuardService {
  constructor(
    private readonly http: HttpClient,
    private readonly utilsSrv: UtilsService,
    private readonly langSrv: LanguageService,
    private readonly metaTagsSrv: MetaTagsService,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const data = route.data;
    const title = data['title'];
    const description = data['description'] || 'meta.default.description';
    const imageUrl = data['imageUrl'] || 'https://www.tripilog.com/assets/img/meta.jpg';
    const path = state.url;

    let seoParamsObs: Observable<SEOParams>;

    if (this.utilsSrv.isPlatformBrowser) {
      seoParamsObs = of(this.getMetaData({ title, description, path, imageUrl }));
    } else {
      seoParamsObs = this.http.get(`assets/i18n/${this.langSrv.getCurrentLang()}.json`).pipe(
        first((res) => !!res),
        map((res) =>
          this.getMetaData({
            ...(title && { title: this.getValueFromKey(res, title) }),
            description: this.getValueFromKey(res, description),
            path,
            imageUrl,
          }),
        ),
      );
    }

    return seoParamsObs.pipe(first((res) => !!res)).pipe(
      map((seoParams) => {
        this.metaTagsSrv.set(seoParams, this.langSrv.getCurrentLang());

        return true;
      }),
    );
  }

  private getMetaData({ title, description, path, imageUrl }: SEOParams): SEOParams {
    return {
      ...(title && { title: this.langSrv.translate(title) }),
      description: this.langSrv.translate(description),
      path,
      imageUrl,
    };
  }

  private getValueFromKey(obj: object, key: string) {
    if (key.indexOf('.') === -1) {
      return obj[key];
    }

    const keys = key.split('.');

    let result = obj;

    for (const k of keys) {
      if (result[k] !== undefined) {
        result = result[k];
      } else {
        return '';
      }
    }

    return result;
  }
}

export const metaTagsGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> => inject(MetaTagsGuardService).canActivateChild(next, state);
