import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';
import { GtagService } from './core/services/gtag/gtag.service';
import { IconModule } from './icon.module';
import { BaseComponent } from './presentation/components/base/base.component';
import { FooterComponent } from './presentation/components/footer/footer.component';
import { HeaderComponent } from './presentation/components/header/header.component';
import { LoaderComponent } from './presentation/components/loader/loader.component';
import { LayoutService } from './presentation/services/layout/layout.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    IconModule,
    MatSidenavModule,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
  @HostBinding('class.tl-ds') addDsClass = true;

  @ViewChild('sidenavRef') set sidenavRef(sidenavRef: MatSidenav) {
    sidenavRef && this.sidenavSrv.set(sidenavRef);
  }

  private readonly layoutSrv: LayoutService = inject(LayoutService);

  constructor(
    private router: Router,
    private gtagSrv: GtagService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutSrv.init();
  }

  ngAfterViewInit(): void {
    if (this.utilsSrv.isPlatformBrowser) {
      this.router.events
        .pipe(filter((event: Event) => event instanceof NavigationEnd))
        .subscribe((ev: NavigationEnd) => {
          this.domSrv.scrollToTop('html', 'instant');
          this.gtagSrv.navSnapshot(ev.urlAfterRedirects);
        });
    }
  }
}
