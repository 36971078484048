<div class="mobile-sidenav" [ngClass]="{ 'logged-in': !!state.user() }">
  <div class="mobile-sidenav__section">
    @if (!!state.user()) {
      <div class="d-flex flex-column align-items-center justify-content-center">
        <app-avatar class="cursor-pointer mb-3" [img]="state.user().img || Placeholder.User" [dimensions]="72">
        </app-avatar>
        <h4 class="text-center mb-0">{{ state.user()?.name }}</h4>
      </div>
    } @else {
      <app-brand></app-brand>
    }
  </div>

  <div class="mobile-sidenav__section">
    <div class="mobile-sidenav__navigation">
      @for (item of navItems(); track item.key) {
        <div class="mobile-sidenav__navigation-item" (click)="navigateFromSidenav(item.path)">
          <app-icon [icon]="item.icon"></app-icon>
          <span>{{ item.label | translate }}</span>
        </div>
      }
    </div>
  </div>

  @if (!state.user()) {
    <div class="mobile-sidenav__section">
      <button
        class="tl-button w-100 mb-2"
        mat-stroked-button
        color="primary"
        (click)="navigateFromSidenav(Paths[Navigation.Login])">
        {{ 'login' | translate }}
      </button>

      <button
        class="tl-button w-100"
        mat-flat-button
        color="primary"
        (click)="navigateFromSidenav(Paths[Navigation.Register])">
        {{ 'create_account' | translate }}
      </button>
    </div>
  }
</div>
