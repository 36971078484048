import { Injectable, inject } from '@angular/core';
import { UtilsService } from '@app/shared/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FbService {
  private readonly utilsSrv: UtilsService = inject(UtilsService);

  loadPixel(): void {
    if (!environment.production) {
      return;
    }

    const { renderer, document } = this.utilsSrv;
    const script = renderer.createElement('script');

    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${environment.fbPixelId}');
      fbq('track', 'PageView');
    `;

    renderer.appendChild(document.head, script);

    const noscript = renderer.createElement('noscript');
    const img = renderer.createElement('img');

    renderer.setAttribute(img, 'height', '1');
    renderer.setAttribute(img, 'width', '1');
    renderer.setAttribute(img, 'style', 'display:none');
    renderer.setAttribute(img, 'src', `https://www.facebook.com/tr?id=${environment.fbPixelId}&ev=PageView&noscript=1`);
    renderer.appendChild(noscript, img);
    renderer.appendChild(document.body, noscript);
  }
}
