<header [ngClass]="{ 'border-bottom': state.pageConfig()?.headerBorderBottom }">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <app-brand class="cursor-pointer" (click)="navigate(!!state.user() ? Paths[Navigation.Feed] : [])"></app-brand>
      </div>

      @if (state.pageConfig()?.headerActions) {
        <div class="col-auto">
          <div class="row align-items-center">
            @if (!!state.user()) {
              <div class="col-auto">
                <div class="row align-items-center d-lg-none">
                  <div class="col-auto">
                    <button mat-flat-button color="primary" (click)="navigate(Paths[Navigation.TripForm])">
                      {{ 'create_trip' | translate }}
                    </button>
                  </div>

                  <div class="col-auto">
                    <div class="px-2">
                      <app-icon class="cursor-pointer" icon="menu" (click)="openMobileMenu()"></app-icon>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center d-none d-lg-flex">
                  <div class="col-auto">
                    <button mat-button color="primary" (click)="navigate(Paths[Navigation.Explore])">
                      {{ 'explore' | translate }}
                    </button>
                  </div>

                  <div class="col-auto">
                    <button mat-flat-button color="primary" (click)="navigate(Paths[Navigation.TripForm])">
                      {{ 'create_trip' | translate }}
                    </button>
                  </div>

                  <!-- <div class="col-auto">
                    <button mat-icon-button>
                      <app-icon icon="notifications"></app-icon>
                    </button>
                  </div> -->

                  <div class="col-auto">
                    <app-avatar
                      class="cursor-pointer"
                      [img]="state.user().img || Placeholder.User"
                      [dimensions]="42"
                      (click)="navigate(Paths[Navigation.Me])">
                    </app-avatar>
                  </div>
                </div>
              </div>
            } @else {
              <!-- <div class="px-2 d-lg-none">
                <app-icon class="cursor-pointer" icon="menu" (click)="openMobileMenu()"></app-icon>
              </div> -->

              <div class="col-auto d-none d-lg-block">
                <button mat-button color="primary" (click)="navigate(Paths[Navigation.Explore])">
                  {{ 'explore' | translate }}
                </button>
              </div>

              <div class="col-auto">
                <div class="cursor-pointer px-2 d-lg-none" (click)="navigate(Paths[Navigation.Login])">
                  <app-icon class="d-lg-none" icon="person"></app-icon>
                </div>

                <button
                  class="d-none d-lg-block"
                  mat-stroked-button
                  color="primary"
                  (click)="navigate(Paths[Navigation.Login])">
                  {{ 'login' | translate }}
                </button>
              </div>

              <div class="col-auto d-none d-lg-block">
                <button mat-flat-button color="primary" (click)="navigate(Paths[Navigation.Register])">
                  {{ 'create_account' | translate }}
                </button>
              </div>

              <div class="col-auto">
                <app-language-switcher></app-language-switcher>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</header>

<ng-template #mobileSidenavTpl>
  <app-mobile-sidenav></app-mobile-sidenav>
</ng-template>
