import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule],
})
export class IconModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    const icons = [
      { name: 'instagram', path: '/assets/icons/brands/instagram.svg' },
      { name: 'facebook', path: '/assets/icons/brands/facebook.svg' },
      { name: 'whatsapp', path: '/assets/icons/brands/whatsapp.svg' },
      { name: 'x', path: '/assets/icons/brands/x.svg' },
    ];

    icons.forEach((icon) => {
      iconRegistry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
