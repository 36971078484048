import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { StateService } from '@app/application/services/state/state.service';
import { RouterService } from '@app/core/services/router/router.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly stateSrv: StateService,
    private readonly routerSrv: RouterService,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!!this.stateSrv.state.user()) {
      return true;
    }

    isPlatformBrowser(this.platformId) && this.routerSrv.navigate([]);

    return false;
  }
}

export const authGuard: CanActivateChildFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
  inject(AuthGuardService).canActivateChild(next, state);
