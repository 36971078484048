import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Navigation, Paths } from '@app/core/constants/navigation.constants';
import { NavigationParams } from '@app/presentation/interfaces/navigation.interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { BaseComponent } from '../base/base.component';
import { BrandComponent } from '../brand/brand.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-mobile-sidenav',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, BrandComponent, AvatarComponent, IconComponent],
  templateUrl: './mobile-sidenav.component.html',
  styleUrl: './mobile-sidenav.component.scss',
})
export class MobileSidenavComponent extends BaseComponent {
  private readonly loggedOutNavItems: NavigationParams<string>[] = [
    { key: 'home', label: 'home', icon: 'home', path: [] },
    { key: 'explore', label: 'explore', icon: 'explore', path: Paths[Navigation.Explore] },
  ];

  private readonly loggedInNavItems: NavigationParams<string>[] = [
    { key: 'profile', label: 'profile', icon: 'account_circle', path: Paths[Navigation.Me] },
    { key: 'explore', label: 'explore', icon: 'explore', path: Paths[Navigation.Explore] },
    { key: 'settings', label: 'settings', icon: 'settings', path: Paths[Navigation.Settings] },
  ];

  navItems = computed(() => (!!this.state.user() ? this.loggedInNavItems : this.loggedOutNavItems));

  navigateFromSidenav(path: string[]) {
    this.navigate(path).then(() => {
      this.sidenavSrv.close();
    });
  }
}
