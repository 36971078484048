import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Params, Router, RouterStateSnapshot } from '@angular/router';
import { StateService } from '@app/application/services/state/state.service';
import { DEFAULT_LANG, LANGS_LIST } from '@app/core/constants/global.constants';
import { LanguageService } from '@app/core/services/language/language.service';
import { Lang } from '@app/core/types/lang.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class LangGuardService {
  private readonly router: Router = inject(Router);
  private readonly stateSrv: StateService = inject(StateService);
  private readonly langSrv: LanguageService = inject(LanguageService);

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const urlSegments = state.url.split('/');
    const lang = urlSegments[1] as Lang;
    const supportedLanguages = LANGS_LIST;
    const currentLang = this.stateSrv.state.lang();
    const requiresLang = route.data?.['requiresLang'] ?? true;

    if (!requiresLang) {
      return true;
    }

    if (!supportedLanguages.includes(lang)) {
      this.navigateToLang(state.url, route.queryParams);
      return false;
    } else if (currentLang && lang !== currentLang) {
      this.langSrv.changeLanguage(lang);
    }

    return true;
  }

  private navigateToLang(url: string, queryParams: Params) {
    const currentLang = this.stateSrv.state.lang() || DEFAULT_LANG;
    const baseUrl = url.split('?')[0];

    if (!baseUrl.startsWith(`/${currentLang}`)) {
      const path = `/${currentLang}${baseUrl}`;
      this.router.navigate([path], { queryParams });
    }
  }
}

export const langGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | Observable<boolean> => inject(LangGuardService).canActivateChild(next, state);
