<div class="loader {{ type }}">
  @switch (type) {
    @case ('page') {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }

    @case ('sidenav') {
      <mat-spinner [diameter]="isDesktop ? 74 : 56"></mat-spinner>
    }
  }
</div>
