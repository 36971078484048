import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [MatProgressBarModule, MatProgressSpinnerModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent extends BaseComponent {
  @Input({ required: true }) type: 'page' | 'sidenav';
}
