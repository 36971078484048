<footer>
  <div class="tl-section">
    <div class="container">
      <div class="row-item-lg">
        <app-brand class="cursor-pointer" (click)="navigate([])"></app-brand>
      </div>

      <div class="row-item-lg">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-auto mb-4 mb-lg-0">
            <div class="row">
              <div class="col-auto">
                <span class="cursor-pointer" (click)="navigate(Paths[Navigation.Privacy])">{{
                  'privacy' | translate
                }}</span>
              </div>

              <div class="col-auto">
                <span class="cursor-pointer" (click)="navigate(Paths[Navigation.Terms])">{{
                  'terms' | translate
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-auto">
            <div class="row">
              <div class="col-auto">
                <a href="https://www.instagram.com/tripilogofficial" target="_blank" rel="noopener noreferrer">
                  <mat-icon class="d-flex" svgIcon="instagram"></mat-icon>
                </a>
              </div>

              <div class="col-auto">
                <a
                  href="https://www.facebook.com/profile.php?id=61559915983748"
                  target="_blank"
                  rel="noopener noreferrer">
                  <mat-icon class="d-flex" svgIcon="facebook"></mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row-item-lg">
        <small class="opacity-75">{{ fullYear }} Tripilog. {{ 'all_rights_reserved' | translate }}</small>
      </div>
    </div>
  </div>
</footer>
