import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Trip } from '@app/domain/interfaces/trip.interfaces';
import { User } from '@app/domain/interfaces/user.interfaces';
import { TripsApiService } from '@app/infrastructure/services/trips/trips.service';
import { catchError, map, of, throwError } from 'rxjs';

export const tripDetailResolver: ResolveFn<{ trip: Trip<User> }> = (route, state) => {
  const tripsApiSrv: TripsApiService = inject(TripsApiService);
  const { username, urlSlugLocation, urlSlugTitle } = route.params;
  const cleanedUsername = username.replace('@', '');
  const urlSlug = `${urlSlugLocation}/${urlSlugTitle}`;

  return tripsApiSrv.find(cleanedUsername, urlSlug).pipe(
    map((trip) => ({ trip })),
    catchError((err) => (err.status === 404 ? of(null) : throwError(() => err))),
  );
};
