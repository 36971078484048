import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { StateService } from '@app/application/services/state/state.service';
import { Navigation, Paths } from '@app/core/constants/navigation.constants';
import { RouterService } from '@app/core/services/router/router.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class RedirectGuardService {
  private readonly stateSrv: StateService = inject(StateService);
  private readonly routerSrv: RouterService = inject(RouterService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!!this.stateSrv.state.user()) {
      this.routerSrv.navigate(Paths[Navigation.Me]);

      return false;
    }

    return true;
  }
}

export const redirectGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | Observable<boolean> => inject(RedirectGuardService).canActivate(next, state);
