import { Routes } from '@angular/router';
import { StatePageConfig } from './application/interfaces/state.interface';
import { DEFAULT_LANG } from './core/constants/global.constants';
import { Navigation, Paths, getNavigationRoutePath } from './core/constants/navigation.constants';
import { authGuard } from './core/guards/auth/auth.guard';
import { canonicalGuard } from './core/guards/canonical/canonical.guard';
import { langGuard } from './core/guards/lang/lang.guard';
import { metaTagsGuard } from './core/guards/meta-tags/meta-tags.guard';
import { pageConfigGuard } from './core/guards/page-config/page-config.guard';
import { redirectGuard } from './core/guards/redirect/redirect.guard';
import { tripDetailResolver } from './presentation/resolvers/trip-detail/trip-detail.resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `/${DEFAULT_LANG}`,
    pathMatch: 'full',
  },
  {
    path: ':lang',
    canActivateChild: [langGuard, canonicalGuard, metaTagsGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./presentation/pages/home/home.component').then((c) => c.HomePageComponent),
        canActivate: [pageConfigGuard],
        data: {
          pageConfig: {
            headerBorderBottom: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Explore]),
        loadComponent: () =>
          import('./presentation/pages/explore/explore.component').then((c) => c.ExplorePageComponent),
        canActivate: [pageConfigGuard],
        data: {
          title: 'meta.explore.title',
          description: 'meta.explore.description',
          pageConfig: {
            footer: false,
            headerBorderBottom: true,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Advisor]),
        loadComponent: () =>
          import('./presentation/pages/advisor/advisor.component').then((c) => c.AdvisorPageComponent),
        canActivate: [pageConfigGuard],
        data: {
          title: 'meta.advisor.title',
          description: 'meta.advisor.description',
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Login]),
        loadComponent: () => import('./presentation/pages/login/login.component').then((c) => c.LoginPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Register]),
        loadComponent: () =>
          import('./presentation/pages/register/register.component').then((c) => c.RegisterPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.ForgotPassword]),
        loadComponent: () =>
          import('./presentation/pages/forgot-password/forgot-password.component').then(
            (c) => c.ForgotPasswordPageComponent,
          ),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.ResetPassword]),
        loadComponent: () =>
          import('./presentation/pages/reset-password/reset-password.component').then(
            (c) => c.ResetPasswordPageComponent,
          ),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Feed]),
        loadComponent: () => import('./presentation/pages/feed/feed.component').then((c) => c.FeedPageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.TripForm]),
        loadComponent: () =>
          import('./presentation/pages/trip-form/trip-form.component').then((c) => c.TripFormPageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.TripFormWithId]()),
        loadComponent: () =>
          import('./presentation/pages/trip-form/trip-form.component').then((c) => c.TripFormPageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Me]),
        loadComponent: () => import('./presentation/pages/me/me.component').then((c) => c.MePageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Settings]),
        loadComponent: () =>
          import('./presentation/pages/settings/settings.component').then((c) => c.SettingsPageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            footer: false,
          } as StatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Terms]),
        loadComponent: () => import('./presentation/pages/terms/terms.component').then((c) => c.TermsPageComponent),
        canActivate: [pageConfigGuard],
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Privacy]),
        loadComponent: () =>
          import('./presentation/pages/privacy/privacy.component').then((c) => c.PrivacyPageComponent),
        canActivate: [pageConfigGuard],
      },
    ],
  },
  {
    path: getNavigationRoutePath(Paths[Navigation.User]()),
    loadComponent: () => import('./presentation/pages/user/user.component').then((c) => c.UserPageComponent),
    canActivate: [pageConfigGuard],
    data: {
      pageConfig: {
        requiresLang: false,
        footer: false,
      } as StatePageConfig,
    },
  },
  {
    path: getNavigationRoutePath(Paths[Navigation.Trip]()),
    loadComponent: () => import('./presentation/pages/trip/trip.component').then((c) => c.TripPageComponent),
    canActivate: [canonicalGuard, pageConfigGuard],
    data: {
      pageConfig: {
        requiresLang: false,
      } as StatePageConfig,
    },
    resolve: {
      data: tripDetailResolver,
    },
  },
  {
    path: getNavigationRoutePath(Paths[Navigation.TripsList]()),
    loadComponent: () =>
      import('./presentation/pages/trips-list/trips-list.component').then((c) => c.TripsListPageComponent),
    canActivate: [canonicalGuard, pageConfigGuard],
    data: {
      pageConfig: {
        requiresLang: false,
        footer: false,
      } as StatePageConfig,
    },
  },
  {
    path: '**',
    loadComponent: () =>
      import('./presentation/pages/not-found/not-found.component').then((c) => c.NotFoundPageComponent),
    canActivate: [langGuard, pageConfigGuard],
  },
];
