import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router, provideRouter } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { inject as vercelInject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { QuillConfig, QuillModule } from 'ngx-quill';
import { routes } from './app.routes';
import { DEFAULT_LANG } from './core/constants/global.constants';
import { apiInterceptor } from './core/interceptors/api/api.interceptor';
import { loaderInterceptor } from './core/interceptors/loader/loader.interceptor';
import { ErrorService } from './core/services/error/error.service';
import { InitializerService } from './core/services/initializer/initializer.service';

if (environment.production) {
  vercelInject();
  injectSpeedInsights({ framework: 'Angular' });

  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['https://www.tripilog.com', 'https://tripilog.com'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

registerLocaleData(localeEs);

function initializeApp(initializerSrv: InitializerService): () => Observable<boolean> {
  return () => initializerSrv.init();
}

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const quillConfig: QuillConfig = {
  theme: 'snow',
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([apiInterceptor, loaderInterceptor])),
    importProvidersFrom(
      QuillModule.forRoot(quillConfig),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: DEFAULT_LANG,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
};
